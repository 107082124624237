<template>
  <div class="component-profile w-100">
    <info-cupo />
  </div>
</template>
<script>
import InfoCupo from '@/components/infoCupo/InfoCupo'
export default {
  components: { InfoCupo },
}
</script>
